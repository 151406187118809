<template>
  <div class="">
    <el-dialog
      title="VIP"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page_box">
        <Swiper
          ref="mySwiper"
          class="mySwiper"
          :options="swiperOptions"
          @slideChangeTransitionStart="levelChange"
          @touchEnd="levelChange"
        >
          <swiper-slide
            v-for="(item, index) in levelList"
            :key="index"
            class="level_item"
          >
            <img :src="item.icon" alt="" />
          </swiper-slide>
        </Swiper>
        <div class="vip_title">
          VIP<span>{{ level }}</span>
        </div>
        <div class="vip_scrollbar">
          <div
            class="vip_scrollbar_val"
            :style="`width: ${needExpScrollbar}%`"
          ></div>
        </div>
        <div class="vip_exp_text">
          <span v-if="level2 == level && level < 10">
            Còn cách cấp sau : {{ needExp }}EXP
          </span>
          <span v-else-if="needExp >= 0">
            EXP： {{ exp }}/{{ levelList[level].exp }}
          </span>
          <!-- 该等级已满级 -->
          <span v-else>Đã đạt cấp này</span>
        </div>
        <div class="vip_explain">
          <div class="vip_explain_nav">
            Đặc quyền riêng {{ levelList[level].lok.length }}/9
          </div>
          <div class="vip_explain_box">
            <div
              class="vip_explain_item"
              v-for="(item, index) in vipExplain"
              :key="index"
              @click="goVipPath(item)"
            >
              <div class="vip_explain_info">
                <img
                  class="vip_explain_img"
                  :class="!item.colorShow ? 'vip_explain_img_show' : ''"
                  :src="item.png"
                  alt=""
                />
                <div class="vip_explain_name">{{ item.name }}</div>
                <div
                  class="vip_explain_remark"
                  v-if="item.colorShow && item.remark"
                >
                  {{ item.remark }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vip_level_nav">Cấp độ trưởng thành</div>
        <img class="vip_level_img" src="@/assets/img/vip/level.png" alt="" />
        <div class="vip_level_dec">
          Mỗi lần nạp, Sẽ tính exp theo tỉ lệ 1000VNĐ = 1 EXP, Sẽ tính trực tiếp
          vào tài khoản.Dựa theo danh sách trên, Khi đạt đủ điểm kinh nghiệm sẽ
          nhận cấp VIP tương ứng, Người chơi sẽ được hưởng quyển lợi trên cấp độ
          VIP hiện tại đang có.
        </div>
        <div class="vip_level_dec">
          Đồng thời, Cứ mỗi nữa năm, tài khoản không đạt mốc yêu cầu tích luỹ để
          duy trì cấp sẽ trừ tương ứng số exp, có thể sẽ trong trường hợp giảm
          cấp VIP, cụ thể EXP giảm như sau:
        </div>
        <div class="vip_level_table">
          <div class="vip_level_tr">
            <div class="vip_level_tb">Cấp VIP</div>
            <div class="vip_level_tb">Trừ EXP/nữa năm</div>
            <div class="vip_level_tb">Tích nạp giữ cấp/nữa năm</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">1</div>
            <div class="vip_level_tb">240</div>
            <div class="vip_level_tb">120</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">2</div>
            <div class="vip_level_tb">720</div>
            <div class="vip_level_tb">450</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">3</div>
            <div class="vip_level_tb">1494</div>
            <div class="vip_level_tb">990</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">4</div>
            <div class="vip_level_tb">2656</div>
            <div class="vip_level_tb">1760</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">5</div>
            <div class="vip_level_tb">5395</div>
            <div class="vip_level_tb">3575</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">6</div>
            <div class="vip_level_tb">12800</div>
            <div class="vip_level_tb">8000</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">7</div>
            <div class="vip_level_tb">28000</div>
            <div class="vip_level_tb">17500</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">8</div>
            <div class="vip_level_tb">52500</div>
            <div class="vip_level_tb">35000</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">9</div>
            <div class="vip_level_tb">140000</div>
            <div class="vip_level_tb">80000</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">10</div>
            <div class="vip_level_tb">420000</div>
            <div class="vip_level_tb">240000</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <SignIn ref="signIn" />
    <vipGift ref="vipGift" />
    <vipCoupon ref="vipCoupon" />
    <Nap ref="nap" />
    <retrieve ref="retrieve" />
    <meet ref="meet" />
    <recovery ref="recovery" />
    <bind ref="bind" />
    <unbind ref="unbind" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import SignIn from "./page/signIn.vue";
import vipGift from "./page/vipGift.vue";
import vipCoupon from "./page/vipCoupon.vue";
import Nap from "./page/nap.vue";
import retrieve from "./page/retrieve.vue";
import meet from "./page/meet.vue";
import recovery from "./page/recovery.vue";
import bind from "./page/bind.vue";
import unbind from "./page/unbind.vue";
export default {
  name: "",
  mixins: [],
  components: {
    SignIn,
    vipGift,
    vipCoupon,
    Nap,
    retrieve,
    meet,
    recovery,
    Swiper,
    SwiperSlide,
    bind,
    unbind,
  },
  directives: {
    swiper: directive,
  },
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      bindDialogFormVisible: false,
      unbindDialogFormVisible: false,
      content: "Gửi tin nhắn",
      vipInfo: [],
      gridData: [],
      swiper: null,
      level: 0, // 当前等级
      level2: 0, //备用等级
      exp: 0, // 当前经验
      add_time: "", //相遇时间
      needExp: 0, // 需要升级的经验
      needExpScrollbar: 0, //经验条长度
      modules: null,
      levelList: [
        // vip等级
        {
          level: 0,
          icon: require("@/assets/img/vip/v0.png"),
          exp: 0,
          lok: [0],
          tips2: "",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 1,
          icon: require("@/assets/img/vip/v1.png"),
          exp: 300,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 2,
          icon: require("@/assets/img/vip/v2.png"),
          exp: 900,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 3,
          icon: require("@/assets/img/vip/v3.png"),
          exp: 1800,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 4,
          icon: require("@/assets/img/vip/v4.png"),
          exp: 3200,
          lok: [0, 1, 2, 3],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 5,
          icon: require("@/assets/img/vip/v5.png"),
          exp: 6500,
          lok: [0, 1, 2, 3, 4],
          tips2: "Nhận",
          tips4: "1%",
          tips5: "",
          tips7: "",
        },
        {
          level: 6,
          icon: require("@/assets/img/vip/v6.png"),
          exp: 16000,
          lok: [0, 1, 2, 3, 4, 5],
          tips2: "Nhận",
          tips4: "1%",
          tips5: "1 lần",
          tips7: "",
        },
        {
          level: 7,
          icon: require("@/assets/img/vip/v7.png"),
          exp: 35000,
          lok: [0, 1, 2, 3, 4, 5, 6,7],
          tips2: "Nhận",
          tips4: "1%",
          tips5: "1 lần",
          tips7: "",
        },
        {
          level: 8,
          icon: require("@/assets/img/vip/v8.png"),
          exp: 70000,
          lok: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          tips2: "Nhận",
          tips4: "2%",
          tips5: "1 lần",
          tips7: "1 lần",
        },
        {
          level: 9,
          icon: require("@/assets/img/vip/v9.png"),
          exp: 200000,
          lok: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          tips2: "Nhận",
          tips4: "2%",
          tips5: "1 lần",
          tips7: "2 lần",
        },
        {
          level: 10,
          icon: require("@/assets/img/vip/v10.png"),
          exp: 600000,
          lok: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          tips2: "Nhận",
          tips4: "3%",
          tips5: "1 lần",
          tips7: "3 lần",
        },
      ],
      vipExplain: [
        // vip 权益
        {
          name: "Báo danh",
          path: "vip/signin",
          svg: "qdjf",
          png: require("@/assets/img/vip/qdjf.png"),
          check: true,
          remark: "",
          colorShow: false,
          ref: "signIn",
        },
        {
          name: "Quà VIP",
          path: "vip/vipgift",
          svg: "lb",
          png: require("@/assets/img/vip/lb.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "vipGift",
        },
        {
          name: "Ưu đãi VIP",
          path: "vip/vipcoupon",
          svg: "yhq",
          png: require("@/assets/img/vip/yhq.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "vipCoupon",
        },
        {
          name: "Liên kết",
          path: "vip/bind",
          svg: "bind",
          png: require("@/assets/img/vip/bind.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "bind",
        },
        {
          name: "Thưởng nạp",
          path: "vip/nap",
          svg: "czjl",
          png: require("@/assets/img/vip/czjl.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "nap",
        },
        {
          name: "Tìm lại đã mất",
          path: "vip/retrieve",
          svg: "bdzh",
          png: require("@/assets/img/vip/bdzh.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "retrieve",
        },
        {
          name: "Xoá liên kết",
          path: "vip/unbind",
          svg: "unbind",
          png: require("@/assets/img/vip/unbind.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "unbind",
        },
        {
          name: "Gặp phúc lợi",
          path: "vip/meet",
          svg: "yjfl",
          png: require("@/assets/img/vip/yjfl.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "meet",
        },
        {
          name: "khôi phục thao tác",
          path: "vip/recovery",
          svg: "wczhf",
          png: require("@/assets/img/vip/wczhf.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "recovery",
        },
      ],
      swiperOptions: {
        loop: false,
        autoplay: false, // 自动轮播
        speed: 1000, // 轮播速度
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        slideToClickedSlide: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 15,
          depth: 100,
          modifier: 2,
          slideShadows: false,
        },
        // Some Swiper option/callback...
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.setScrollbar();
  },
  mounted() {
    // this.onSwiper();
  },
  methods: {
    handleClose() {
      this.dialogFormVisible = false;
    },
    setScrollbar() {
      this.levelList.forEach((item, index) => {
        if (this.exp >= item.exp) {
          item.level < 10 && item.level > 0
            ? (this.level = item.level + 1)
            : (this.level = item.level);
        }
      });
      this.showScrollbar();
    },
    showScrollbar() {
      this.levelList[this.level].lok.forEach((item) => {
        if (this.vipExplain[item]) {
          this.vipExplain[item].colorShow = true;
        }
      });
      if (this.level <= 10) {
        this.needExp =
          this.level == this.level2 && this.levelList[Number(this.level) + 1]
            ? this.levelList[Number(this.level) + 1].exp - this.exp
            : this.levelList[this.level].exp - this.exp;
        this.needExpScrollbar =
          this.level == this.level2 && this.levelList[Number(this.level) + 1]
            ? (this.exp / this.levelList[Number(this.level) + 1].exp).toFixed(
                2
              ) * 100
            : (this.exp / this.levelList[this.level].exp).toFixed(2) * 100;
      }
    },
    onSwiper() {
      this.swiper.slideTo(this.level, false);
    },
    resetColorShow() {
      //将每个icon置空
      this.levelList[10].lok.forEach((item) => {
        if (this.vipExplain[item]) {
          this.vipExplain[item].colorShow = false;
        }
      });
    },
    levelChange() {
      this.level = this.swiper.activeIndex;
      this.chooseRemark();
      this.resetColorShow();
      this.showScrollbar();
    },
    //获取Icon高亮
    goVipPath(item) {
      if (!item.colorShow) return;
      this.$refs[item.ref].dialogFormVisible = true;
      this.dialogFormVisible = false;
      switch (item.ref) {
        case "meet":
          this.$refs[item.ref].add_time = this.add_time;
          break;
        case "vipGift":
          let serveInfo = this.$store.state.ServeInfo;
          this.$refs[item.ref].servers_roles.servers = serveInfo;
          this.$refs[item.ref].userInfo.level = this.level2;
          break;
        case "bind":
          this.$refs[item.ref].vipInfo = this.vipInfo;
          break;
        case "unbind":
          this.$refs[item.ref].handleUnBind();
          break;
        default:
          break;
      }
      // this.$router.push(item.path);
    },
    getSwiper() {
      this.$nextTick(() => {
        this.swiper = this.$refs.mySwiper.$swiper;
        this.swiper.slideTo(this.level, false);
        this.chooseRemark();
        this.resetColorShow();
        this.showScrollbar();
      });
    },
    //充值remark
    chooseRemark() {
      if (this.levelList[this.level].tips2) {
        this.vipExplain[1].remark = this.levelList[this.level].tips2;
      }
      if (this.levelList[this.level].tips4) {
        this.vipExplain[4].remark = this.levelList[this.level].tips4;
      }
      if (this.levelList[this.level].tips5) {
        this.vipExplain[5].remark = this.levelList[this.level].tips5;
      }
      if (this.levelList[this.level].tips7) {
        this.vipExplain[8].remark = this.levelList[this.level].tips7;
      }
    },
  },
};
</script>

<style scoped lang="scss">
$vip-color: #409eff;
/deep/.el-dialog {
  width: 700px;
}
div {
  white-space: pre-wrap;
  word-break: break-word;
}
.page_box {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mySwiper {
  width: 100%;
  min-height: 32px;
  height: 150px;
  overflow: hidden;
}
.level_item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4.8px;
  width: 50% !important;
  box-sizing: border-box;
}
.level_item img {
  width: 180px;
}
.vip_title {
  font-weight: bold;
  font-size: 24px;
  color: $vip-color;
}
.vip_scrollbar {
  margin: 2.4px 0;
  width: 75%;
  height: 12px;
  min-height: 12px;
  background-color: #e1e1e1;
  border-radius: 16px;
  overflow: hidden;
}
.vip_scrollbar_val {
  width: 0;
  height: 100%;
  background-color: $vip-color;
}
.vip_exp_text {
  font-size: 16px;
  font-weight: bold;
}
.vip_explain {
  margin-top: 3.2px;
  width: 95%;
  max-height: 310px;
  background-color: rgba(17, 136, 255, 0.1);
  border-radius: 10px;
}
.vip_explain_nav {
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #409eff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
}
.vip_explain_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 3.2px;
}
.vip_explain_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  width: 16.5%;
  cursor: pointer;
  position: relative;
}
.vip_explain_remark {
  position: absolute;
  top: -5px;
  right: -8px;
  width: 50px;
  text-align: center;
  border-radius: 14px 15px 15px 0;
  background-color: red;
  color: #fff;
}
.vip_explain_icon {
  padding: 2.4px;
  width: 50px;
  height: 50px;
  min-height: 50px;
  background-color: #f0d4a0;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.vip_explain_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
.vip_explain_img {
  width: 50%;
}
.vip_explain_img_show {
  filter: grayscale(100%);
}
.vip_explain_icon_show {
  background-color: #e1e1e1;
}
.vip_explain_icon svg {
  position: absolute;
  left: 15%;
  top: 15%;
  width: 40px;
  height: 40px;
}
.vip_explain_title {
  margin-top: 1.6px;
  font-size: 16px;
  color: #000;
}
.vip_explain_title_show {
  margin-top: 1.6px;
  font-size: 16px;
  color: #8d8d8d;
}
.vip_level_nav {
  margin: 32px auto 0;
  padding: 5px 0px;
  color: $vip-color;
  // border: 1px solid $vip-color;
  // border-radius: 30px;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  font-size: 24px;
}
.vip_level_img {
  margin: 4.8px 0;
  width: 95%;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 100%;
  border-left: 0.0625rem solid #ddd;
  border-bottom: 0.0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 32px;
  font-size: 16px;
  border-top: 0.0625rem solid #ddd;
  border-right: 0.0625rem solid #ddd;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
  /deep/.el-dialog__body {
    padding: 25px 15px 30px;
  }

  .vip_explain_item {
    padding: 8px 0;
    width: 25%;
  }
  .vip_explain_remark {
    top: -8px;
    right: 0px;
    width: 40px;
  }
  .level_item img {
    width: 115px;
  }
  .vip_level_tb {
    font-size: 12px;
  }
  .vip_explain_box {
    justify-content: flex-start;
    text-align: center;
  }
}
</style>
