<template>
  <div class="">
    <el-dialog
      title="Nhật ký đổi"
      v-if="logDialog"
      :width="$isMobile ? '90%' : ''"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      center
    >
      <div
        class="pointOrder_list"
        ref="scrollContainer"
        @scroll="handleScroll"
        v-loading="logLoading"
      >
        <div
          class="pointOrder_item"
          v-for="item in logList.data"
          :key="item.platform_orderno"
        >
          <div class="pointOrder_cell">
            <div>{{ item.goods_name }}</div>
            <div>{{ item.update_time }}</div>
          </div>
          <div class="pointOrder_cell">
            <div>
              giá bán : <span>{{ item.point }} Điểm </span>
            </div>
          </div>
          <div class="pointOrder_cell">
            <div>No : {{ item.platform_orderno }}</div>
          </div>
          <div class="pointOrder_cell" v-if="item.remark.gift_code">
            <div>Code quà : {{ item.remark.gift_code }}</div>
            <div
              @click="copyCode(item.remark.gift_code)"
              class="pointOrder_cell_btn"
            >
              Copy
            </div>
          </div>
          <div class="pointOrder_cell">
            <div class="pointOrder_status">
              Trạng thái : {{ getStatusText(item.status) }}
            </div>
          </div>
          <div class="pointOrder_cell" v-if="item.deliver_time">
            <div class="pointOrder_status">
              Thời gian giao hàng : {{ item.deliver_time }}
            </div>
          </div>
        </div>
        <div class="pointOrder_noData" v-if="showAll">
          Đã tải tất cả dữ liệu
        </div>
        <div v-loading="loading"></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> Nạp thẻ </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      logDialog: false,
      logList: {
        data: [],
      },
      anotherData: [],
      page: 1, // 当前页码
      logLoading: true, //logloading
      loading: false, //是否正在加载更多
      scrollContainer: null, // 滚动容器
      scrollThreshold: 100, // 触发下拉加载的距离阈值
      showAll: false, //滚到底部了
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 获取滚动容器的引用
    // 初始化列表数据
  },
  methods: {
    confirm() {
      this.dialogFormVisible = false;
    },
    getData() {
      this.fetchData();
    },
    fetchData() {
      // 发送API请求，获取新的数据
      let params = {
        page: this.page,
      };
      this.$http.get("/point/orderList", { params }).then((res) => {
        if (res.data.code === 200) {
          this.anotherData = res.data.data;
          this.logList.data = [...this.logList.data, ...this.anotherData];
          this.logLoading = false;
          //  this.logList.data.forEach((item) => {
          //   if (item.remark) {
          //     item.remark = JSON.parse(item.remark);
          //   }
          // });
          this.page += 1;
          this.loading = false;
          // // 将新的数据合并到原有数据中
          if (this.anotherData.length < 10) {
            this.showAll = true;
            this.loading = false;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    handleScroll() {
      if (this.showAll) {
        return;
      }
      this.scrollContainer = this.$refs.scrollContainer;
      const containerHeight = this.scrollContainer.offsetHeight;
      const scrollTop = this.scrollContainer.scrollTop;
      const scrollHeight = this.scrollContainer.scrollHeight;
      if (
        scrollTop + containerHeight >= scrollHeight - this.scrollThreshold &&
        !this.loading
      ) {
        this.loading = true;
        setTimeout(() => {
          this.fetchData();
        }, 1000);
      }
    },
    handleClose() {
      this.dialogFormVisible = false;
      this.logDialog = false;
    },
    getStatusText(key) {
      let text = "";
      switch (key) {
        case "0": // 未支付
          text = "Chưa thanh toán";
          break;
        case "1": // 待发货
          text = "Chờ gửi hàng";
          break;
        case "2": // 已发货
          text = "Đã gửi";
          break;
      }
      return text;
    },
    copyCode(val) {
      const save = function (e) {
        e.clipboardData.setData("text/plain", val);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener("copy", save); // 添加一个copy事件
      document.execCommand("copy"); // 执行copy方法
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
.pointOrder_list {
  padding: 0 10px;
  width: 100%;
  height: 500px;
  overflow-y: auto;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f4f4;
  transition: 0.3s;
  .pointOrder_item {
    padding: 2.4px 0;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    color: #000;
    .pointOrder_cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &:nth-child(1) {
        margin-bottom: 15px;
      }
      div:nth-child(1) {
        flex: 1;
      }
      .pointOrder_cell_btn {
        padding: 0 0.2rem;
        line-height: 0.5rem;
        color: #1188FF;
      }
    }
  }
  .pointOrder_noData {
    margin: 30px 0;
    width: 100%;
    text-align: center;
    color: #999;
  }
}
@media screen and (max-width: 1000px) {
  .pointOrder_list {
    .pointOrder_item {
      font-size: 10px;
    }
  }
}
</style>
