<template>
  <div class="page">
    <el-dialog
      title="Nạp hoàn tiền ví"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
    <div class="page_box">
      <!-- <div class="vip_level_nav">充值返平台币</div> -->
      <div class="vip_level_dec">
        Người chơi thông qua nạp để nhận điểm tương ứng với số tiền đã nạp, Sẽ nhận lại thưởng theo tỉ lệ như sau :
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">Cấp VIP</div>
          <div class="vip_level_tb">Thưởng thêm</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">5 ~ 7</div>
          <div class="vip_level_tb">Thưởng 1% giá trị đã nạp</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">8 ~ 9</div>
          <div class="vip_level_tb">Thưởng 2% giá trị đã nạp</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">10</div>
          <div class="vip_level_tb">Thưởng 3% giá trị đã nạp</div>
        </div>
      </div>
    </div>
    </el-dialog>
   
  </div>
</template>
<script>

export default {
  data(){
    return {
      dialogFormVisible : false
    }
  },
  methods: {
    handleClose() {
      this.$parent.dialogFormVisible = true;
      this.dialogFormVisible = false;
    },
  }
}
</script>
<style scoped lang="scss">
$vip-color: #1188FF;
div{
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.el-dialog {
  width: 700px;
}
.page_box {
  padding: 0 3.52px 16px 3.52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.vip_level_nav {
  margin: 6.4px 0 1.6px 0;
  padding: 0 32px;
  font-size: 16px;
  line-height: 32px;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 16px;
}
.vip_level_img {
  margin: 4.8px 0;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 95%;
  border-left: .0625rem solid #ddd;
  border-bottom: .0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
  font-size: 16px;
  border-top: .0625rem solid #ddd;
  border-right: .0625rem solid #ddd;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
}
</style>