<template>
  <div class="">
    <el-dialog
      title="Mở khoá"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page-content">
        <div class="pay-input-bg">
          <div class="pay-input-name">{{ chargeInfo.coin_type || "VND" }}:</div>
          <div id="pay_input" class="pay_input">
            {{ chargeInfo.charge_coin }}
          </div>
        </div>
      </div>
      <div class="page-content">
        <el-radio-group v-model="radio">
          <el-radio
            :label="item.pay_type"
            border
            v-for="item in chargeInfo.atm"
            :key="item.pay_type"
            >{{ item.name }}</el-radio
          >
          <!-- <el-radio :label="2" border>BankQR</el-radio>
          <el-radio :label="3" border>ATMCARD</el-radio>
          <el-radio :label="6" border>VISA</el-radio>
          <el-radio :label="4" border>Nganluong</el-radio>
          <el-radio :label="5" border>Internet Banking</el-radio> -->
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> Nạp thẻ </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      radio: "",
      chargeInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm: debounce(function () {
      {
        if (!this.radio) {
          this.$message.warning("Hãy chọn mục");
          return;
        }
        const { charge_id, charge_coin, country } = this.chargeInfo;
        let params = {
          pay_type: this.radio,
          pay_amt: charge_coin,
          charge_id: charge_id,
        };
        this.$http
          .post(`/pay/chargeCoin?country=${country}`, params)
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.data.redirect_type === 2) {
                this.$router.push({
                  path: "/payCode",
                  query: {
                    redirect_url: res.data.data.redirect_url,
                    order_no: res.data.data.order_no,
                    type: res.data.data.type,
                  },
                });
              } else {
                window.location.href = res.data.data.redirect_url;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
        this.dialogFormVisible = false;
      }
    }, 300),
    getData() {
      let params = {};
      this.$http.get("/pay/chargeDetail", { params }).then((res) => {
        if (res.data.code === 200) {
          this.chargeInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.radio = "";
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0px;
  margin-top: 10px;
}
.page-content {
  margin: 8px 0;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 1.6px;
  box-shadow: 0 0 5px #eee;
  .pay-input-bg {
    display: flex;
    align-items: center;
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    width: 100%;
    box-sizing: border-box;
    .pay_input {
      flex: 1;
      display: block;
      padding: 0 10px;
      font-size: 24px;
      color: #ff2b11;
      line-height: 12.8px;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  /deep/.el-radio.is-bordered {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
